import { images } from '@/asset';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import DynamicContentBanner from '@/components/DynamicContentBanner';
import PromotionsCard from '@/components/PromotionCard';
import RegularText from '@/components/RegularText';
import WarningBanner from '@/components/WarningBanner';
import CustomCard from '@/components/customCard';
import Layout from '@/components/layout';
import { config } from '@/config';
import { Ga4Event } from '@/constants/enum';
import { openCrisp } from '@/helpers';
import { capitalizeFirstLetter } from '@/helpers/string';
import { useInView } from '@/hooks/useInView';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import bannerSlice, {
  BannerSliceKeys,
  embeddedBannerSelector,
  isVisibleBannerBlockify,
  isVisibleModalAppQuantityBreakSelector,
  isVisiblePromotionCardSelector,
  isVisibleRecommendAppsSelector,
} from '@/redux/slice/banner.slice';
import { dashboardDataSelector } from '@/redux/slice/dashboard.slice';
import {
  isShowedBlockifySelector,
  isShowedPromoSyncTrackSelector,
  isShowedRecommendedAppsSelector,
  isSubmittedBlockifySelector,
  sessionSlice,
} from '@/redux/slice/session.slice';
import { isVisibleThinkTodos } from '@/redux/slice/thinksToDo.slice';
import { Button, Card, Divider, Icon, InlineGrid, Link, SkeletonBodyText, Text, Tooltip } from '@shopify/polaris';
import {
  ButtonIcon,
  ChannelsIcon,
  ChatIcon,
  MenuHorizontalIcon,
  PlanIcon,
  QuestionCircleIcon,
  SendIcon,
  SmileyHappyIcon,
  XIcon,
} from '@shopify/polaris-icons';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../Widgets/component/Table';
import Analytics from './components/Analytics';
import ThingsToDoNext from './components/ThingsToDoNext';
import RecommendedApps from './components/recommendedApps';
import { HomeStyled } from './styled';

interface ActionItemProps {
  content: React.ReactNode;
  prefix: React.ReactNode;
  isHiddenDivider?: boolean;
  disable?: boolean;
  onAction?: () => void;
}

const HomePage = () => {
  const dispatch = useDispatch();
  const isSkip = useSelector(isSkipApiSelector);
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });

  const embeddedBanner = useSelector(embeddedBannerSelector);
  const dashboard = useSelector(dashboardDataSelector);
  const isVisibleThinkTodoNext = useSelector(isVisibleThinkTodos);
  const isVisibleRecommendApps = useSelector(isVisibleRecommendAppsSelector);
  const isVisibleBlockify = useSelector(isVisibleBannerBlockify);
  const isVisiblePromotionCard = useSelector(isVisiblePromotionCardSelector);
  const isShowedBlockify = useSelector(isShowedBlockifySelector);
  const isSubmittedBlockify = useSelector(isSubmittedBlockifySelector);
  const isShowedRecommendedApps = useSelector(isShowedRecommendedAppsSelector);
  const isVisibleModalAppQuantityBreak = useSelector(isVisibleModalAppQuantityBreakSelector);
  const isShowedPromoSyncTrack = useSelector(isShowedPromoSyncTrackSelector);

  const [sendGA4Event] = apiCaller.useSendGA4EventMutation();
  const [getEmbeddedAppStatus] = apiCaller.useLazyGetEmbeddedAppStatusQuery();
  const { data, isLoading } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });

  const blockifyRef = useRef<HTMLDivElement>(null);
  const recommendAppsRef = useRef<HTMLDivElement>(null);
  const promoSyncTrackRef = useRef<HTMLDivElement>(null);
  const isRecommendAppsInView = useInView(recommendAppsRef);
  const isBlockifyInView = useInView(blockifyRef);
  const isPromoSyncTrackInView = useInView(promoSyncTrackRef);

  const [isShowEmbedBanner, setIsShowEmbedBanner] = useState(false);
  // const [isShowModalAppQuantityBreak, setIsShowModalAppQuantityBreak] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isVisibleModalAppQuantityBreak)
      timer = setTimeout(() => {
        // setIsShowModalAppQuantityBreak(true);
        sendGA4Event({ eventName: Ga4Event.QuantityBreakImpression, params: {} });
      }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, isVisibleModalAppQuantityBreak, sendGA4Event]);

  useEffect(() => {
    if (!isVisibleThinkTodoNext) {
      const timer = setTimeout(() => {
        getEmbeddedAppStatus().then((res) => {
          if (res.data?.data) {
            setIsShowEmbedBanner(!res.data.data.isAppEnabled);
          }
        });
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const ActionItems = ({ content, prefix, isHiddenDivider, disable, onAction }: ActionItemProps) => {
    return (
      <div style={{ cursor: onAction ? 'pointer' : 'auto' }} onClick={onAction} className={disable ? 'disable' : ''}>
        <div className="action-item-container">
          <div className="d-flex">
            <span>{prefix}</span> <div className="ml-8">{content}</div>
          </div>
        </div>
        {!isHiddenDivider && <Divider borderColor="border" />}
      </div>
    );
  };
  const listAction: Array<ActionItemProps> = useMemo(() => {
    const result = [
      {
        content: (
          <Text variant="bodyMd" as="p">
            Omega Chat is <b>{capitalizeFirstLetter(data?.data.plan || '')}</b>
          </Text>
        ),
        prefix: <Icon source={PlanIcon} tone="subdued" />,
      },
      {
        content: (
          <Text variant="bodyMd" as="p">
            <b>{data?.data.countGroupButtons}</b> widgets created
          </Text>
        ),
        prefix: <Icon source={ChannelsIcon} tone="subdued" />,
      },
      {
        content: (
          <Text variant="bodyMd" as="p">
            You have a total of <b>{data?.data.countButtons}</b> buttons
          </Text>
        ),
        prefix: <Icon source={ButtonIcon} tone="subdued" />,
      },
      {
        content: (
          <Text variant="bodyMd" as="p">
            Get <b>help and support</b>
          </Text>
        ),
        prefix: <Icon source={QuestionCircleIcon} tone="subdued" />,
        onAction: () => {
          try {
            $crisp.push(['do', 'chat:open']);
          } catch (error) {
            console.log(error);
          }
        },
      },
    ];
    return result;
  }, [data?.data.countButtons, data?.data.countGroupButtons, data?.data.plan]);

  const onDismissBanner = (key: BannerSliceKeys) => {
    dispatch(bannerSlice.actions.handleVisibleBanner({ key, value: false }));
  };

  const handleSendGA4Event = useCallback(
    (event: Ga4Event) => {
      const dataSubmit = {
        eventName: event,
        params: {},
      };
      if (event === Ga4Event.BlockifyClick) {
        if (!isSubmittedBlockify) {
          sendGA4Event(dataSubmit);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedBlockify', value: true }));
        }
      } else {
        sendGA4Event(dataSubmit);
      }
    },
    [dispatch, isSubmittedBlockify, sendGA4Event],
  );

  useEffect(() => {
    if (!isShowedBlockify && isBlockifyInView) {
      dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isShowedBlockify', value: true }));
      handleSendGA4Event(Ga4Event.BlockifyImpression);
    }
  }, [dispatch, handleSendGA4Event, isBlockifyInView, isShowedBlockify]);
  useEffect(() => {
    if (!isShowedPromoSyncTrack && isPromoSyncTrackInView) {
      dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isShowedPromoSyncTrack', value: true }));
      handleSendGA4Event(Ga4Event.PromoSyncTrackImpression);
    }
  }, [dispatch, handleSendGA4Event, isPromoSyncTrackInView, isShowedPromoSyncTrack]);

  useEffect(() => {
    if (!isShowedRecommendedApps && isRecommendAppsInView) {
      dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isShowedRecommendedApps', value: true }));
      handleSendGA4Event(Ga4Event.EstimatedImpression);
      handleSendGA4Event(Ga4Event.ReturnImpression);
      handleSendGA4Event(Ga4Event.OderTrackingImpression);
    }
  }, [dispatch, handleSendGA4Event, isRecommendAppsInView, isShowedRecommendedApps]);

  return (
    <Layout title="Omega Chat">
      <HomeStyled>
        <RegularText>Let's build a premium chat experience for your leads</RegularText>

        <DynamicContentBanner />

        <div className="mt-16 dashboard-container">
          <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
            <div className="dashboard-img">
              <img src={images.dashboard} alt="_blank" />
            </div>
            <Card>
              {isLoading ? (
                <SkeletonBodyText lines={6} />
              ) : (
                listAction.map((item, index) => {
                  return <ActionItems {...item} isHiddenDivider={listAction.length === index + 1} key={index} />;
                })
              )}
            </Card>
          </InlineGrid>
        </div>

        <div className="mt-16">
          <WarningBanner
            isVisible={isShowEmbedBanner && embeddedBanner}
            title="Enable the app embed on your theme"
            onDismiss={() => {
              dispatch(bannerSlice.actions.handleVisibleBanner({ key: 'embeddedBanner', value: false }));
            }}
            hiddenContactSupport
          >
            <RegularText>
              To ensure the Omega Chat works with your theme, please make sure to turn on the app in the Theme Editor.
            </RegularText>
            <div className="mt-8">
              <Button
                onClick={() => {
                  window.open(
                    `https://${config.shop}/admin/themes/current/editor?context=apps&activateAppId=${process.env.REACT_APP_THEME_APP_EXTENSION_ID}/omg-facebook-chat`,
                    '_blank',
                  );
                }}
              >
                Turn on
              </Button>
            </div>
          </WarningBanner>
        </div>

        <div className="mt-16">
          {data?.data.countGroupButtons === 0 ? (
            <Card padding={'400'}>
              <Table endTime={dashboard.endDate.getTime()} startTime={dashboard.startDate.getTime()} />
            </Card>
          ) : (
            <Analytics isInHomePage={true} />
          )}
        </div>

        {isVisibleThinkTodoNext && (
          <div className="mt-16">
            <ThingsToDoNext numberGroupButtons={data?.data.countButtons || 0} />
          </div>
        )}

        {isVisiblePromotionCard && (
          <div className="mt-16" ref={promoSyncTrackRef}>
            <PromotionsCard />
          </div>
        )}

        {isVisibleBlockify && (
          <div className="mt-16" ref={blockifyRef}>
            <BannerTemplate onDismiss={() => onDismissBanner('isVisibleBannerBlockify')} src={images.blockify}>
              <BoldText>Make it easy to control store visitors and protect your store</BoldText>
              <div className="mt-16">
                <RegularText>
                  Blockify allows you to easily block visitors based on location and IP, reducing fraud and blocking bots. Analyze
                  traffic, identify risks, and enhance store protection with Visitor Analytics. Say goodbye to scammers & copycat!
                </RegularText>
              </div>

              <div className="mt-16">
                <Button
                  variant="primary"
                  onClick={() => {
                    handleSendGA4Event(Ga4Event.BlockifyClick);
                    window.open(
                      'https://apps.shopify.com/blockify?utm_campaign=crs&utm_source=fbchat&utm_medium=homeinapp',
                      '_blank',
                    );
                  }}
                >
                  Try now
                </Button>
              </div>
            </BannerTemplate>
          </div>
        )}

        {/* <div className="mt-16">
            <BannerTemplate src={images.dashboardSynctrack} onDismiss={() => onDismissBanner('isVisibleBannerSynctrack')}>
              <BoldText>
                Auto-sync PayPal tracking info & Stripe.
                <br />
                Faster PayPal funds release and build trust with PayPal
              </BoldText>
              <div className="mt-16">
                <RegularText>
                  Synctrack adds tracking info to PayPal & Stripe automatically and instantly on autopilot. That helps you reduce
                  Stripe and PayPal disputes, money on hold, PayPal limits, and reserves. Synctrack keeps your business
                  transparent, saves time, and avoids PayPal disputes!
                </RegularText>
              </div>

              <div className="mt-16 d-flex">
                <Button
                  variant="primary"
                  onClick={() => {
                    window.open(
                      'https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=fbchat&utm_medium=homeinapp',
                      '_blank',
                    );
                  }}
                >
                  Try for free
                </Button>
                <div className="ml-8">
                  <Button onClick={openCrisp}>Discuss with our team</Button>
                </div>
              </div>
            </BannerTemplate>
          </div> */}

        <div className="mt-16">
          <CustomCard title={'Help center'}>
            <div className="homepage-help-center">
              <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
                <Card>
                  <Link removeUnderline url="mailto:contact@omegatheme.com" target="_blank">
                    <Icon source={SendIcon} tone="info" /> Get email support
                  </Link>
                  <Text as="p">Email us, and we'll get back to you as soon as possible.</Text>
                </Card>
                <Card>
                  <Link removeUnderline onClick={openCrisp}>
                    <Icon source={ChatIcon} tone="info" />
                    Start live chat
                  </Link>
                  <Text as="p">Talk to us directly via live chat to get help with your question.</Text>
                </Card>
                <Card>
                  <Link removeUnderline url="" target="_blank">
                    <Icon source={SmileyHappyIcon} tone="info" />
                    Our help docs
                  </Link>
                  <Text as="p">Find a solution to your problem with our documents and tutorials.</Text>
                </Card>
              </InlineGrid>
            </div>
          </CustomCard>
        </div>

        {isVisibleRecommendApps ? (
          <div className="mt-16">
            <Card>
              <div className="apps-title">
                <div>
                  <BoldText>Omega recommendations for store protection</BoldText>
                  <RegularText>
                    Hi {dataSetting?.data?.shopName || ''}, working with thousand merchants, we know how overwhelming customer
                    inquiries can get. To help reduce support tickets and improve customer satisfaction, here are 3 apps we trust
                    to make a big impact:
                  </RegularText>
                </div>

                <div className="recommend-apps-controller">
                  <div onClick={() => window.open('https://synctrack.io/partners/', '_blank')}>
                    <Tooltip content="View all">
                      <Icon source={MenuHorizontalIcon} />
                    </Tooltip>
                  </div>

                  <div
                    className="ml-8"
                    onClick={() => {
                      dispatch(bannerSlice.actions.handleVisibleBanner({ key: 'isVisibleRecommendApps', value: false }));
                    }}
                  >
                    <Tooltip content="Dismiss">
                      <Icon source={XIcon} tone="base" />
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className="mt-16 apps-wrapper" ref={recommendAppsRef}>
                <RecommendedApps />
              </div>
            </Card>
          </div>
        ) : null}

        {/* <Modal
          open={isShowModalAppQuantityBreak}
          onClose={() => {
            // setIsShowModalAppQuantityBreak(false);
            dispatch(bannerSlice.actions.handleVisibleBanner({ key: 'isVisibleModalAppQuantityBreak', value: false }));
          }}
          title={''}
          titleHidden
        >
          <Modal.Section>
            <div
              className="d-flex flex-column justify-center align-center gap-10 mt-8"
              style={{ paddingBottom: '12px' }}
              id="modal-promo-quantity-break"
            >
              <img width={120} src={images.app_quantity_break_1} alt="app-quantity-break" />
              <b style={{ fontSize: '1.25rem', marginTop: '12px' }}>Time’s Ticking! Maximize your sale now</b>
              <p style={{ fontSize: '0.8rem' }} className="mb-8">
                Strategic discounts is a key factor for customer to buy more
              </p>
              <div className="d-flex w-full align-center gap-10 mt-8" style={{ padding: '0 40px' }}>
                <img width={54} src={images.app_quantity_break_2} alt="app-quantity-break-icon" />
                <div>
                  <b style={{ fontSize: '1.1rem', color: '#0079CC' }}>Quantity breaks</b>
                  <p style={{ fontSize: '0.8rem' }}>
                    Motivate customers to purchase more to get bigger savings, make the most of BFCM offers.
                  </p>
                </div>
              </div>
              <div className="d-flex w-full align-center gap-10 mt-8 mb-16" style={{ padding: '0 40px' }}>
                <img width={54} src={images.app_quantity_break_3} alt="app-quantity-break-icon" />
                <div>
                  <b style={{ fontSize: '1.1rem', color: '#0079CC' }}>Bundle offer</b>
                  <p style={{ fontSize: '0.8rem' }}>Boost sales, move more inventory with attractive deals</p>
                </div>
              </div>

              <Button
                onClick={() => {
                  // setIsShowModalAppQuantityBreak(false);
                  window.open(
                    'https://apps.shopify.com/quantity-price-breaks-limit-purchase?utm_campaign=crs&utm_source=fbchat&utm_medium=popupbanner',
                    '_blank',
                  );
                  sendGA4Event({ eventName: Ga4Event.QuantityBreakClick, params: {} });
                  dispatch(bannerSlice.actions.handleVisibleBanner({ key: 'isVisibleModalAppQuantityBreak', value: false }));
                }}
                variant="primary"
              >
                Yes, I want to do it now!
              </Button>
            </div>
          </Modal.Section>
        </Modal> */}
      </HomeStyled>
    </Layout>
  );
};

export default memo(HomePage);
